import React from "react";
import { graphql } from "gatsby";
import GlobalLayout from "../global/GlobalLayout";
import { IContentTableOfContentsLink } from "../content/ContentTableOfContents";
import SpaceBody from "../content/SpaceBody";
import { slugify } from "../../includes/utils";
import { IArticleSearchResult } from "../search/SearchBody";

export interface ISortedArticles {
  anchors: IContentTableOfContentsLink[];
  articles: {
    [key: number]: IArticleSearchResult[];
  }
}

interface ISpaceProps {
  data: ISpaceQuery;
}
  
const Space = ({ data }: ISpaceProps): JSX.Element => {
  const { title, childPages } = data.confluenceSpace.spaceData;

  const getSortedArticles = (): ISortedArticles => {
    const sortedArticles: ISortedArticles = {
      anchors: [],
      articles: {}
    };

    const years: number[] = [];
    for (const article of childPages) {
      const year: number = new Date(article.articleData.createdDate).getFullYear();

      if (!years.includes(year)) {
        years.push(year);
      }

      const articles: IArticleSearchResult[] = sortedArticles.articles[year] ?? [];
      articles.push({
        slug: article.slug,
        articleData: article.articleData
      });

      sortedArticles.articles[year] = articles;
    }

    for (const year of years) {
      const yearString: string = `Articles from ${year.toString()}`;
      
      sortedArticles.anchors.push({
        title: yearString,
        slug: slugify(yearString),
        isChild: false
      });
    }

    return sortedArticles;
  };

  return (
    <GlobalLayout siteMetadata={data.site.siteMetadata} smoothScroll={true}>
      <SpaceBody title={title} sortedArticles={getSortedArticles()}/>
    </GlobalLayout>
  );
};
export default Space;

interface ISpaceQuery {
  site: {
    siteMetadata: {
      name: string;
      description: string;
    }
  }
  confluenceSpace: {
    spaceData: {
      title: string;
      childPages: {
        slug: string;
        articleData: {
          title: string;
          labels: string[];
          createdDate: string;
          readTime: number;
        }
      }[]
    }
  }
}
  
export const query = graphql`
  query Space($id: String) {
    site {
      siteMetadata {
        name
        description
      }
    }
    confluenceSpace(id: { eq: $id }) {
      spaceData {
        title
        childPages {
          slug
          articleData {
            title
            labels
            createdDate
            readTime
          }
        }
      }
    }
  }
`;
import React from "react";
import { ISortedArticles } from "../page-templates/Space";
import { ArticleResultCard } from "../search/ArticleResultCard";
import ContentAds from "./ContentAds";
import ContentHeader from "./ContentHeader";
import ContentTableOfContents from "./ContentTableOfContents";
import "./SpaceBody.scss";

export interface ISpaceBodyProps {
  title: string;
  sortedArticles: ISortedArticles;
}
  
const SpaceBody = ({ title, sortedArticles }: ISpaceBodyProps): JSX.Element => {
  const getArticles = (): JSX.Element | JSX.Element[] => {
    if (Object.keys(sortedArticles.articles).length === 0) {
      return <div>This space does not contain any articles.</div>;
    }

    const articleElements: JSX.Element[] = [];

    for (const year in sortedArticles.articles) {
      articleElements.push(<h2 key={year}>Articles from {year}</h2>);

      for (const article of sortedArticles.articles[year]) {
        articleElements.push(<ArticleResultCard key={article.slug} slug={article.slug} {...article.articleData}/>);
      }
    }

    return articleElements;
  };

  return (
    <div className="SpaceBody__wrapper">
      <ContentHeader title={`${title} Articles`}/>
      <div className="SpaceBody">
        {sortedArticles ? <ContentTableOfContents title={title} anchors={sortedArticles.anchors}/> : ""}
        <section className="SpaceBody__content">{getArticles()}</section>
        <ContentAds/>
      </div>
    </div>
  );
};
export default SpaceBody;
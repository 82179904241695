import { Link } from "gatsby";
import React from "react";
import { formatCreatedDate } from "../../includes/utils";
import "./ArticleResultCard.scss";

interface IArticleResultCardProps {
  title: string;
  labels: string[];
  createdDate: Date | string;
  readTime: number;
  slug: string;
}

export const ArticleResultCard = ({ title, labels, createdDate, readTime, slug }: IArticleResultCardProps): JSX.Element => {
  const getLabels = (): (JSX.Element | void)[] => labels.map((label: string, index: number): JSX.Element | void => {
    if (index >= 3) {
      return;
    }

    return <div key={label} className="SearchResultCard__label">{label}</div>;
  });

  return (
    <Link to={`/${slug}`} className="ArticleResultCard SearchBody__resultCard">
      <h4>{title}</h4>
      <div className="ArticleResultCard__metadata">
        <div className="ArticleResultCard__createdDate">Created on {formatCreatedDate(createdDate)}</div>
        <div className="ArticleResultCard__readTime">{readTime} min read</div>
      </div>
      <div className="ArticleResultCard__labelWrapper">{getLabels()}</div>
    </Link>
  );
};